'use client';

import React from 'react';
import Skeleton from '@lobox/uikit/Skeleton/Skeleton';
import Flex from '@lobox/uikit/Flex/Flex.component';
import cnj from '@lobox/uikit/utils/cnj';
import Media from '@lobox/uikit/Media';
import classes from './loading.module.scss';

export default function loading() {
  return (
    <>
      <Flex className={classes.profileRoot}>
        <Flex className={cnj(classes.profileHeaderContainer)}>
          <Flex className={classes.headerBackground}>
            <Flex className={classes.blur} />
            <Skeleton className={classes.backgroundSkeleton} />
          </Flex>
          <Flex className={classes.profileInfoContainer}>
            <Flex className={cnj(classes.userInfoWrapper)}>
              <Flex className={classes.headerAvatarRoot}>
                <Media at="tablet">
                  <Skeleton className={classes.avatar__tablet} />
                </Media>
                <Media greaterThan="tablet">
                  <Skeleton className={classes.avatar__greaterThan_tablet} />
                </Media>
              </Flex>
              <Flex className={classes.userInfoContainer}>
                <Skeleton style={{ width: 50, height: 29 }} />
                <Flex className={classes.userNameWrapper}>
                  <Skeleton style={{ width: 45, height: 19 }} />
                </Flex>
                <Skeleton style={{ width: 40, height: 19 }} />
                <Flex className={cnj(classes.followersInfoWrapper)}>
                  <Skeleton style={{ width: 90, height: 20 }} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
